$(
	function() {
		app.assignNumberControl();
		app.setSelectedLink();
		app.addRegisterCheckBoxes();
		app.assignProductOver();
		app.attachSvgEvents();
		app.setBackUrls();
		app.addCoverSpan();
		app.saveProductListInfo();
		app.correctMainPartHeight();
		app.initIndexCats();
		app.initSchemaColors();
		app.animateProductList();
		app.correctTopMenuPopups({
			maxPopupItemLength: 9
		});
		app.initFloatingHeader({
            noTopFixDiv: true,
			floatSelector: '._ .header',
			floatFix: 122
		});
        app.hamburger.init();
	}
);

var app = {
	_vars: {
		multOrderVersion: 'V2'
	},
    _VERSION: 2,
    _VERSION_TRANS_NOT_COMPLETED: true,
	mobileOptions: {
		showSearch: true
	},
	messages : {},
	productAvailableQuantityUrl : null,
	optionsWereInitialized : false,
    doAfterStoreCardsInit: function(props) {
		$('#dv-my-card').addClass('_done');
	},
	animateProductList: function() {

		if ($('.product-list2').length === 0) {
			return false;
		}

		$('._ .list-grid ul li.grid a')
			.append('<span class="q1"></span>' +
				'<span class="q2"></span>' +
				'<span class="q3"></span>' +
				'<span class="q4"></span>');

		$('._ .list-grid ul li.line a')
			.append('<span class="q1"></span>' +
				'<span class="q2"></span>');

		$('.product-list2 .item .prod-btn').each(
			function() {
				$(this).attr('title', $(this).html());
			}
		);
		$('.product-list2 .item, .product-list2 .item *').mouseenter (
			function() {
				if ($('.product-list2').hasClass('line')) {
					return false;
				}
				if (!$(this).hasClass('item')) {
					var $item = $(this).closest('.item');
					$item.trigger('mouseenter');
					return ;
				}

				var h = $(this).find('.name').outerHeight();
				$(this).find('.image').css('margin-top', (h - 10) + 'px');
				$(this).find('.arrow').css('top', (h - 1) + 'px');
				$(this).find('.price').css('top', ($(this).outerHeight() - 90) + 'px');

			}
		);
		$('.product-list2 .item').mouseleave (
			function() {
				if (!$(this).hasClass('item')) {
					var $item = $(this).closest('.item');
					$item.trigger('mouseleave');
					return ;
				}

				$(this).find('.image').css('margin-top', 0);
				$(this).find('.arrow').css('top', '-30px');
				$(this).find('.price').css('top', '225px');
			}
		).append('<div class="arrow schema-brd-color"></div>');
	},
	doBeforeAssignEditor: function() {
		$('._ .menu > ul > li').each(
			function() {
				if (!$(this).attr('id')) {
					return;
				}
				$(this).attr('data-udu-editor', 'fa-v2')
					.data('fa-selector', '#' + $(this).attr('id') + ' > div > a::before');
			}
		);
		$('._ .index-categories li').each(
			function() {
				$(this).find('.image > div')
					.attr('data-udu-editor', 'fa-v2')
					.data('fa-selector', '#' + $(this).attr('id') + ' .image > div::after');
			}
		);
		$('._ .big-icons > div').each(
			function() {
				$(this).attr('data-udu-editor', 'fa-v2')
					.data('fa-selector', '#' + $(this).attr('id') + ' a::after');
				$(this).find('.cart-count')
					.attr('data-udu-editor', 'editor2')
					.attr('data-udu-selector', '#' + $(this).attr('id') + ' .cart-count');
			}
		);
		$('._ .index-categories .index-add-text, #slider .nivo-add-html').each(
			function() {
				$(this).attr('data-udu-editor', 'editor2')
					.attr('data-udu-selector', '._ .index-categories .index-add-text');
			}
		);
		$('.top-menu').attr('data-udu-editor', 'simpleListHeader');
	},
    callSearchPopup: function() {
        var that = this;

        var $div = $('.div-advanced-search');
        if ($div.length === 0) {
            $('._ .top').after('<div id="advanced-search-form" class="div-advanced-search loading closed"></div>');
            $div = $('.div-advanced-search');
            setTimeout(
                function() {
                    $div.removeClass('closed');
                },
                20
            );
            var $tplDiv = $('#__advanced-search-form');
            if ($tplDiv.length === 1) {
                app._showSearchDiv($div, $tplDiv.html());
            } else {
                $.post(
                    that.ajaxProcessURLs.process,
                    {
                        op: 'search-popup'
                    },
                    function(res) {
                        app._showSearchDiv($div, res);
                    }
                );
            }

        } else {
            if ($div.hasClass('closed')) {
                $div.removeClass('closed');
                $('body, html').animate({
                    scrollTop: 0
                }, 800);
            } else {
                $div.addClass('closed');
            }
        }
    },
    _showSearchDiv: function($div, res) {
        $div.fadeTo(
            200,
            0.1,
            function() {
                $div.html(res);
                $div.removeClass('loading');
                $div.fadeTo(
                    200,
                    1
                );
                $('body, html').animate({
                    scrollTop: 0
                }, 800);

                if (window.admin) {
                    admin.assignEditor();
                }
            }
        );
    },
	initSchemaColors: function() {
		var color = this.getClassColor('schema-color');
		$("<style type='text/css'> " +
			"._ .top .center-part .header .my-cart a:hover::after, " +
			"._ .list-grid ul li:hover a span, ._ .list-grid ul li.selected a span {border-color: " + color + "} " +
			"._ .list-grid ul li.selected a span, .nivo-controlNav a {background-color: " + color + " !important} " +
			".blog-popular li:hover .image, .blog-popular li.selected .image {border-color: " + color + "} " +
			"._ .main .center-part div.li-block.blog-popular li a:hover, ._ .main .center-part div.li-block.blog-popular li a.selected {color: " + color + " !important} " +
			"._ .product-list2:not(.line) div.item:hover div.name {background-color: " + color + ";border: 1px solid " + color + "} " +
			"</style>")
			.appendTo("head");

		$('.pagination-menu').addClass('schema-bg-color-over-lnk');
	},
	doBeforePriceSliderShown: function(trg) {
		$('.noUi-connect').css('background-color', this.getClassColor('schema-color'));
	},
	makeCustomSlider:function(duration) {
		this.makeIndexSlider(duration);
		this.correctSliderLinks();
		setTimeout(
			function() {
				$('.nivo-controlNav').addClass('shown');
			},
			1000
		);
		var that = this;
		$(window).resize(
			function() {
				that.correctSliderLinks();
			}
		);
	},
	correctSliderLinks: function() {
		$('.nivo-controlNav').addClass('schema-after-brd-color');
		var $links = $('.nivo-controlNav a').addClass('schema-bg-color');
		if ($('.nivo-controlNav a span').length === 0) {
			$links.each(
				function() {
					$(this).html('<span>' + $(this).html() + '</span>')
				}
			);
		}
	},
	initIndexCats: function() {
		$('.index-categories li.item').mouseover(
			function() {
				$(this).find('.image').addClass('schema-bg-color')
			}
		).mouseleave(
			function() {
				$(this).find('.image').removeClass('schema-bg-color')
			}
		).click(
			function() {
				document.location = $(this).find('a').attr('href');
			}
		)
	}
};